import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import customFetch from "../../services/api";
import { Button, Modal } from "antd";
import { storageBaseUrl } from "../../services/urls";
import { LinkOutlined } from "@ant-design/icons";
function convertTo12HourFormat(time24) {
  const [hours, minutes] = (time24 || "00:00")?.split(":");
  let hours12 = parseInt(hours, 10);
  hours12 = hours12 % 12 || 12;
  const time12 = `${hours12}:${minutes}`;

  return time12;
}
const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [prayerTimes, setPrayerTimes] = useState({});
  const [courses, setCourses] = useState([]);
  const [articles, setArticles] = useState([]);
  const handleClickCourses = () => {
    navigate("/courses");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const getPrayerTimes = async (year, month, latitude, longitude) => {
      try {
        console.log(year, "     ", month, "     ", latitude, "        ", longitude);
        const method = 2; // Calculation method: MWL
        const response = await fetch(
          `https://api.aladhan.com/v1/calendar/${year}/${month}?latitude=${latitude}&longitude=${longitude}&method=${method}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const prayerTimes = data.data[0].timings;
        setPrayerTimes(prayerTimes);
      } catch (error) {
        console.error("Error fetching prayer times:", error.message);
      }
    };

    var date = new Date();
    var month = date.getUTCMonth() + 1; // Months are indexed from 0 to 11
    var year = date.getUTCFullYear();
    navigator.geolocation.getCurrentPosition((position) => {
      getPrayerTimes(year, month, position.coords.latitude, position.coords.longitude);
    }, (error) => {
      console.error("Error fetching location");
    });

  }, []);
  useEffect(() => {
    const fetchcourses = async () => {
      try {
        const response = await customFetch("/courses");
        if (response.status === 200) {
          setCourses(response.data.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching courses:", error.message);
      }
    };
    fetchcourses();
  }, []);
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await customFetch("/articles");
        if (response.status === 200) {
          setArticles(response.data.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching articles:", error.message);
      }
    };
    fetchArticles();
  }, []);
  return (
    <>
      <section>
        <div class="w-100 position-relative">
          <div class="feat-wrap v1 text-center position-relative w-100">
            <div class="feat-caro">
              <div class="feat-item">
                <div
                  class="feat-img position-absolute"
                  style={{ backgroundImage: "url(images/slider/slide1.jpg)" }}
                ></div>
                <div class="feat-cap-wrap position-absolute d-inline-block">
                  <div class="feat-cap d-inline-block">
                    <h2 class="mb-0">دورات التجويد</h2>
                    <p class="mb-0">
                      لدينا أفضل المعلمين و المدربين الحاصلين علي شهادات موثقة{" "}
                    </p>
                    <a class="thm-btn thm-bg" href="/courses" onClick={handleClickCourses}>
                      الدورات
                    </a>
                  </div>
                </div>
              </div>
              {/* <div class="feat-item">
                <div
                  class="feat-img position-absolute"
                  style={{ backgroundImage: "url(images/slider/slide2.jpg)" }}
                ></div>
                <div class="feat-cap-wrap position-absolute d-inline-block">
                  <div class="feat-cap d-inline-block">
                    <h2 class="mb-0"> الموجز البسيط في علم التجويد</h2>
                    <p class="mb-0">
                      لدينا مكتبة قرآنية و تلاوات قرآنية للقارئين المتميزين مثل
                      الحصري مجود والمنشاوي
                    </p>
                  </div>
                </div>
              </div>
              <div class="feat-item">
                <div
                  class="feat-img position-absolute"
                  style={{ backgroundImage: "url(images/slider/slide3.jpg)" }}
                ></div>
                <div class="feat-cap-wrap position-absolute d-inline-block">
                  <div class="feat-cap d-inline-block">
                    <h2 class="mb-0"> تطبيق الموبايل</h2>
                    <p class="mb-0">
                      تطبيقنا به الكثير من الخصائص التي تفيد المسلم كمواقيت
                      الصلاة والقرآن الكريم، كتاب التجويد
                    </p>
                  </div>
                </div>
              </div>
              <div class="feat-item">
                <div
                  class="feat-img position-absolute"
                  style={{ backgroundImage: "url(images/slider/ramadan.jpg)" }}
                ></div>
                <div class="feat-cap-wrap position-absolute d-inline-block">
                  <div class="feat-cap d-inline-block">
                    <h2 class="mb-0"> رمضان كريم</h2>
                    <p class="mb-0">كل عام وأنتم بخير </p>
                  </div>
                </div>
              </div>
              <div class="feat-item">
                <div
                  class="feat-img position-absolute"
                  style={{ backgroundImage: "url(images/slider/eid.jpg)" }}
                ></div>
                <div class="feat-cap-wrap position-absolute d-inline-block">
                  <div class="feat-cap d-inline-block">
                    <h2 class="mb-0">عيد الفطر المبارك</h2>
                    <p class="mb-0">كل عام وأنتم بخير </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 position-relative">
          <div class="time-wrap2 w-100">
            <div class="row align-items-center">
              <div class="col-md-12 col-12 col-lg-2">
                <div class="time-title w-100">
                  <h4 class="mb-0">مواقيت الصلاة</h4>
                  <p class="mb-0 thm-clr">حسب موقعك</p>
                </div>
              </div>
              <div class="col-md-12 col-12 col-lg-10">
                <ul class="time-list2 d-flex flex-wrap w-100 mb-0 list-unstyled">
                  <li>
                    <img src="images/salah/الفجر.svg" alt="" />
                    الفجر<span>{prayerTimes?.Fajr} ص</span>
                  </li>
                  <li>
                    <img src="images/salah/الشروق.svg" alt="" />
                    الشروق<span>{prayerTimes?.Sunrise} ص</span>
                  </li>
                  <li>
                    <img src="images/salah/الظهر.svg" alt="" />
                    الظهر<span>{prayerTimes?.Dhuhr} م</span>
                  </li>
                  <li>
                    <img src="images/salah/العصر.svg" alt="" />
                    العصر
                    <span>{convertTo12HourFormat(prayerTimes?.Asr)} م</span>
                  </li>
                  <li>
                    <img src="images/salah/المغرب.svg" alt="" />
                    المغرب
                    <span>{convertTo12HourFormat(prayerTimes?.Maghrib)} م</span>
                  </li>
                  <li>
                    <img src="images/salah/العشاء.svg" alt="" />
                    العشاء
                    <span>{convertTo12HourFormat(prayerTimes?.Isha)} م</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-50 pb-50 position-relative">
          <div class="container">
            <div class="services-detail-wrap w-100">
              <div class="services-detail-info-wrap w-100">
                <div class="row align-items-center">
                  <div class="col-md-6 col-12 col-lg-4">
                    <div class="service-detail-img position-relative w-100 text-center">
                      <img
                        class="img-fluid"
                        src="images/book.jpg"
                        alt="كتاب التجويد"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12 col-lg-8">
                    <div class="services-detail-info w-100 mt-0">
                      <h2 class="d-inline-block text-color1">
                        الموجز البسيط{" "}
                        <span class="thm-clr">في علم التجويد </span>
                      </h2>
                      <p>
                        قامت خادمة القرآن الشيخة زينب بنت حلمي بن قطب الأبجي
                        بطرح أسلوب التجويد بطريقةٍ مبسّطة لِيَسهُل على كثير من
                        الناس تعلّم أحكام تجويد القرآن الكريم
                      </p>
                      <ul class="services-info-list list-unstyled">
                        <li>
                          استهلَتّ الكتاب بذكر فضل القرآن الكريم، وفضل تعلّمه
                          وتعليمهِ للناس.
                        </li>
                        <li>
                          ومن ثم شرعَت ببيان الأحرف السبعة التي علّمها سيدنا
                          جبريل -عليه السلام- رسول الله -صلى الله عليهِ وسلّم.
                        </li>
                        <li>
                          وانتقلت إلى الشروع ببيان أهمّية أحكام التجويد، وفضلها
                          في تسهيل تدبّر آيات القرآن وتلاوته.
                        </li>
                        <li>
                          أوضحت ضرورة تعلّم أحكام التجويد، ورسّخ أن هذا العلم هو
                          علمٌ يختص بهِ من يشاء الله من عباده.
                        </li>
                      </ul>
                      <a class="thm-btn mini-btn thm-bg me-2" href="/books">
                        قراءة الكتاب{" "}
                      </a>
                      <a
                        role="button"
                        class="thm-border-btn mini-btn"
                        onClick={showModal}
                      >
                        شراء الكتاب
                      </a>
                      <Modal class="mb-0" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={['']}>
                        <div>
                          <h4 class="modal-title">شراء الكتاب</h4>
                          <div>
                            <h5 class="thm-clr">مصر</h5>
                            <p class="mb-0"><i class="fa fa-map-marker-alt text-color1"></i>
                              المكتبة الأكاديمية - 121 شارع التحرير - الدقي
                              القاهرة</p>
                            <p><i class="fa fa-phone-alt text-color1"></i> 01002515014 - 01002515013</p>
                            <h5 class="thm-clr mb-3">الولايات المتحدة الأمريكية </h5>
                            <a class="thm-border-btn px-3 py-2" target="_blank"
                              href="https://furqaanbookstore.com/a-brief-guide-to-the-science-of-tajweed-al-mujaz-al-basit-fii-ilm-al-tajweed/">شراء
                              الآن</a>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-50 pb-50 position-relative bg-white">
          <div class="container">
            <div class="sec-title text-center w-100">
              <div class="sec-title-inner d-inline-block">
                <h2 class="mb-0">أحدث الدورات</h2>
              </div>
            </div>
            <div class="event-wrap w-100">
              <div class="row">
                {courses.map((c) => (
                  <div class="col-md-6 col-lg-4">
                    <div class="event-box w-100">
                      <div class="event-img overflow-hidden position-relative w-100">
                        <a href={"courses/" + c.id} title="">
                          <img
                            class="img-fluid w-100"
                            src={storageBaseUrl + c.image}
                            alt=""
                          />
                        </a>
                      </div>
                      <div class="event-info">
                        <span class="event-loc d-block thm-clr">
                          <i class="fas fa-map-marker-alt"></i>منصة زووم
                        </span>
                        <h3 class="mb-0 fixed-height-title">
                          <a href={"courses/" + c.id} title="">
                            {c.title}
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div class="view-more d-inline-block text-center w-100">
            <a
              class="thm-btn mini-btn thm-bg"
              href="/courses"
              onClick={handleClickCourses}
              title=""
            >
              جميع الدورات
            </a>
          </div>
        </div>
      </section>
      <section>
        <div class="w-100 pt-50 position-relative">
          <div class="container">
            <div class="sec-title text-center w-100">
              <div class="sec-title-inner d-inline-block">
                <h2 class="mb-0">أحدث المقالات</h2>
              </div>
            </div>
            <div class="blog-wrap res-row w-100">
              <div class="row">
                {(articles || []).map((a) => (
                  <div class="col-md-6 col-lg-4">
                    <div class="post-box w-100">
                      <div class="post-img overflow-hidden position-relative w-100">
                        <a href={"/articles/" + a.id} title="">
                          <img
                            class="img-fluid w-100"
                            src={storageBaseUrl + a.image}
                            alt="Blog Image 1"
                          />
                        </a>
                      </div>
                      <div class="post-info position-relative w-100">
                        <a class="thm-bg" href={"/articles/" + a.id} title="">
                          <LinkOutlined />
                        </a>
                        <span class="post-date thm-clr">22 ديسمبر 2023</span>
                        <h3 class="mb-0 fixed-height-title">
                          <a href={"/articles/" + a.id} title="">
                            {a?.title}{" "}
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class="view-more d-inline-block text-center w-100">
              <a class="thm-btn mini-btn thm-bg" href="/news" title="">
                جميع المقالات{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
