import {
  UserOutlined,
  ContainerOutlined,
  FileImageOutlined,
  FileSearchOutlined,
  GroupOutlined,
} from "@ant-design/icons";

export const adminItems = [
  {
    key: "articles",
    icon: <FileSearchOutlined />,
    label: "المقالات",
    path: "/admin/articles/list",
  },
  {
    key: "galleries",
    icon: <FileImageOutlined />,
    label: "المعرض",
    path: "/admin/galleries/list",
  },
  // {
  //   key: "الصور",
  //   icon: <FileImageOutlined />,
  //   label: "الصور",
  //   path: "/admin/images/list",
  // },

  {
    key: "الكورسات",
    icon: <GroupOutlined />,
    label: "الكورسات",
    path: "/admin/courses/list",
  },
  {
    key: "الكتب",
    icon: <ContainerOutlined />,
    label: "الكتب",
    path: "/admin/books/list",
  },
  // {
  //   key: "فصول الكورس ",
  //   icon: <ContainerOutlined />,
  //   label: "فصول الكورس ",
  //   path: "/admin/chapters/list",
  // },
  // {
  //   key: "محتوي الفصول",
  //   icon: <ContainerOutlined />,
  //   label: "محتوي الفصول",
  //   path: "/admin/contents/list",
  // },
  {
    key: " المتقدمين ",
    icon: <UserOutlined />,
    label: " المتقدمين",
    path: "/admin/applications/list",
  },
];
