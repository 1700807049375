import { useState } from "react";
import { message } from "antd";
import customFetch from "../../admin/services/api";
import { SocialIcon } from 'react-social-icons';
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined, FacebookOutlined } from "@ant-design/icons";
const NewAppointment = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await customFetch("/login", {
      method: "POST",
      body: formData,
    });
    if (res.status === 200 || res.status === 201) {
      message.success({
        content: "تم تسجيل الدخول بنجاح",
        duration: 2,
      });
      const token = res.data.token;
      const user = res.data.user;
      localStorage.setItem("applicantToken", token);
      localStorage.setItem("applicant", JSON.stringify(user));
      navigate("/my-courses");
      window.location.reload();
    } else {
      message.error({
        content: "تاكد من صحه كلمه المرور او اسم المستخدم",
        duration: 2,
      });
    }
    console.log("Form Data:", formData);
  };
  return (
    <>
      <section>
        <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
          <div
            class="fixed-bg"
            style={{ backgroundImage: " url(images/page-title-bg.jpg)" }}
          ></div>
          <div class="container">
            <div class="page-title-wrap text-center w-100">
              <div class="page-title-inner d-inline-block">
                <h1 class="mb-0"> تسجيل دخول</h1>
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <a href="index.html">الرئيسية</a>
                  </li>
                  <li class="breadcrumb-item active"> تسجيل دخول</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="w-100 py-5">
        <div class="container">
          <div class="contact-wrap">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-lg-6">
                <div class="cont-info-desc w-100">
                  <p>
                    قم بإدخال الايميل وكلمه المرور
                  </p>
                  <h6 class="mb-0">اتصل بنا</h6>
                  <ul class="cont-info-list2 list-unstyled w-100">
                    <li>
                      <a href="tel:0100225255" dir="ltr">
                        0100 225255
                      </a>
                    </li>
                    <li>
                      <a href="tel:0100225255" dir="ltr">
                        0100 225255
                      </a>
                    </li>
                  </ul>
                  <h6 class="mb-0">تابعنا علي السوشيال ميديا</h6>
                  <div class="social-links2 v2 text-center d-inline-flex">
                    <SocialIcon url="https://www.facebook.com/Almojaz.Albaseet" />
                    <SocialIcon url="https://www.youtube.com/@zeinabhelmy6873" />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-lg-6">
                <form className="cont-form w-100" onSubmit={handleSubmit}>
                  <label>الايميل </label>
                  <input
                    type="text"
                    placeholder=""
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <label>كلمة المرور </label>
                  <div style={{ marginBottom: "20px" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      style={{ width: "92%", height: "50px" }}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      style={{ height: "50px", width: "8%" }}
                    >
                      {showPassword ? (
                        <EyeOutlined />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </button>
                  </div>
                  <div className="d-flex">
                    <button className="thm-btn thm-bg" type="submit">
                      تسجيل
                    </button>
                    <a
                      style={{ color: "#174c78", margin: "20px 10px " }}
                      href="/forget-password"
                      target="_blank"
                    >
                      هل نسيت كلمه المرور؟
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default NewAppointment;
