import "./App.css";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "./admin/layouts/admin-layouts";

import AppLayout from "./layouts/app-layout";
import { protectedRoutes, publicRoutes } from "./admin/constants/routes";
import React, { lazy, Suspense } from "react";
import Login from "./admin/components/login";
import Home from "./components/home";
import UserLogin from "./components/user-login";
import MyCourses from "./components/my-courses";

import { Spin } from "antd";

const importComponent = (path) => {
  return lazy(() => import(`./${path}`));
};
const routeComponents = protectedRoutes.map(({ path, filePath }) => ({
  path: `/${path.toLowerCase()}`,
  component: importComponent(filePath),
}));
const publicRouteComponents = publicRoutes.map(({ path, filePath }) => ({
  path: `/${path.toLowerCase()}`,
  component: importComponent(`components/${filePath}`),
}));

function App() {
  const isAuthenticated = localStorage.getItem("token") !== null;
  const isApplicantAuthenticated =
    localStorage.getItem("applicantToken") !== null;

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {isAuthenticated &&
            routeComponents.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <Suspense
                    fallback={
                      <div>
                        {" "}
                        <Spin tip="Loading" size="large" />
                      </div>
                    }
                  >
                    <AdminLayout>
                      <div className="arabic-text">
                        <route.component />
                      </div>
                    </AdminLayout>
                  </Suspense>
                }
              />
            ))}

          {publicRouteComponents.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense
                  fallback={
                    <div>
                      {" "}
                      <Spin tip="Loading" size="large" />
                    </div>
                  }
                >
                  <AppLayout>
                    <route.component />
                  </AppLayout>
                </Suspense>
              }
            />
          ))}
          {!isAuthenticated ? (
            <Route path="/login" element={<Login />} />
          ) : (
            <Route
              path="/login"
              element={<Navigate replace to="/admin/articles/list" />}
            />
          )}
          {!isApplicantAuthenticated ? (
            <Route path="/user-login" element={<UserLogin />} />
          ) : (
            <Route
              path="/my-courses"
              element={
                <AppLayout>
                  <MyCourses />
                </AppLayout>
              }
            />
          )}
          <Route path="*" exact={true} element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
