import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "./custom.css";
import { LogoutOutlined } from "@ant-design/icons";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const HeaderNav = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("applicantToken") !== null;
  const handleAboutClick = () => {
    navigate("/about");
  };
  const handleNewsClick = () => {
    navigate("/news");
  };
  const handleCoursesClick = () => {
    navigate("/courses");
  };
  const handleChildrenClick = () => {
    navigate("/children-books");
  };
  const handleGalleryClick = () => {
    navigate("/gallery");
  };
  const handleLoginClick = () => {
    navigate("/user-login");
  };
  const handleHomeClick = () => {
    navigate("/");
  };
  const handleArabicBooksClick = () => {
    navigate("/books");
  };
  const handleEnglishBooksClick = () => {
    navigate("/englishbooks");
  };
  const handleBookViedoClick = () => {
    navigate("/book-videos");
  };
  const handleAudioClick = () => {
    navigate("/audios");
  };
  const handleAudioMenshawyClick = () => {
    navigate("/audios-menshawy");
  };
  const handleMyCoursesClick = () => {
    navigate("/my-courses");
  };
  const handleLogoutClick = () => {
    localStorage.removeItem("applicantToken");
    navigate("/");
    window.location.reload();
  };
  return (
    <Navbar expand="lg" bg="white" className="w-100">
      <div className="container-fluid">
        <Navbar.Brand href="/">
          <img
            src="../images/header-logo.png"
            alt="الموجز البسيط"
            height="30"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav" className="justify-content-center">
          <Nav className="align-items-center">
            <ul class="navbar-nav align-items-center">
              <li class="nav-item active">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleHomeClick}
                >
                  الرئيسية
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleCoursesClick}
                >
                  الدورات
                </a>
              </li>
              <Dropdown class="nav-item dropdown">
                <Dropdown.Toggle
                  variant="secondary"
                  className="nav-link dropdown-toggle"
                  id="bookDropdown"
                >
                  كتاب التجويد
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ cursor: "pointer" }}
                    onClick={handleArabicBooksClick}
                  >
                    قراءة الكتاب باللغة العربية
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ cursor: "pointer" }}
                    onClick={handleEnglishBooksClick}
                  >
                    قراءة الكتاب باللغةالانجليزية
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ cursor: "pointer" }}
                    onClick={handleBookViedoClick}
                  >
                    شرح بالفيديوهات
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <li class="nav-item">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleChildrenClick}
                >
                 كتاب تحفة الأطفال
                </a>
              </li>
              <Dropdown class="nav-item dropdown">
                <Dropdown.Toggle
                  variant="secondary"
                  className="nav-link dropdown-toggle"
                  id="bookDropdown"
                >
                  التلاوات الصوتية{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ cursor: "pointer" }}
                    onClick={handleAudioClick}
                  >
                    {" "}
                    الحصري مجود
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ cursor: "pointer" }}
                    onClick={handleAudioMenshawyClick}
                  >
                    المنشاوي{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <li class="nav-item">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleNewsClick}
                >
                  المقالات{" "}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleGalleryClick}
                >
                  معرض الصور
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleAboutClick}
                >
                  من نحن
                </a>
              </li>
              {isAuthenticated ? (
                <>
                  <li class="nav-item">
                    <a
                      class="nav-link  thm-btn thm-bg"
                      onClick={handleMyCoursesClick}
                      style={{ marginLeft: "30px", cursor: "pointer" }}
                    >
                      كورساتي
                    </a>
                  </li>
                  <li class="nav-item">
                    <LogoutOutlined onClick={handleLogoutClick} />
                  </li>
                </>
              ) : (
                <li class="nav-item">
                  <a
                    class="nav-link  thm-btn thm-bg"
                    style={{ cursor: "pointer" }}
                    onClick={handleLoginClick}
                  >
                    تسجيل دخول
                  </a>
                </li>
              )}
            </ul>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
export default HeaderNav;
