export const protectedRoutes = [
  { path: "/admin/articles/list", filePath: "admin/components/articles/list" },
  {
    path: "/admin/articles/create",
    filePath: "admin/components/articles/create",
  },
  {
    path: "/admin/articles/update/:id",
    filePath: "admin/components/articles/update",
  },

  {
    path: "/admin/galleries/list",
    filePath: "admin/components/galleries/list",
  },
  {
    path: "/admin/galleries/create",
    filePath: "admin/components/galleries/create",
  },
  {
    path: "/admin/galleries/update/:id",
    filePath: "admin/components/galleries/update",
  },

  { path: "/admin/images/list", filePath: "admin/components/images/list" },
  { path: "/admin/images/create", filePath: "admin/components/images/create" },
  {
    path: "/admin/images/update/:id",
    filePath: "admin/components/images/update",
  },

  { path: "/admin/courses/list", filePath: "admin/components/courses/list" },
  {
    path: "/admin/courses/create",
    filePath: "admin/components/courses/create",
  },
  {
    path: "/admin/courses/update/:id",
    filePath: "admin/components/courses/update",
  },

  { path: "/admin/books/list", filePath: "admin/components/books/list" },
  { path: "/admin/books/create", filePath: "admin/components/books/create" },
  {
    path: "/admin/books/update/:id",
    filePath: "admin/components/books/update",
  },

  { path: "/admin/chapters/list", filePath: "admin/components/chapters/list" },
  {
    path: "/admin/chapters/create",
    filePath: "admin/components/chapters/create",
  },
  {
    path: "/admin/chapters/update/:id",
    filePath: "admin/components/chapters/update",
  },

  { path: "/admin/contents/list", filePath: "admin/components/content/list" },
  {
    path: "/admin/contents/create",
    filePath: "admin/components/content/create",
  },
  {
    path: "/admin/contents/update/:id",
    filePath: "admin/components/content/update",
  },
  {
    path: "/admin/applications/list",
    filePath: "admin/components/applications/list",
  },
  {
    path: "/admin/applications/create",
    filePath: "admin/components/applications/create",
  },
  {
    path: "/admin/applications/update/:id",
    filePath: "admin/components/applications/update",
  },
];
const publicPages = [
  "home",
  "news",
  "courses",
  "about",
  "gallery",
  "user-login",
  "books",
  "englishbooks",
  "children-books",
  "audios",
  "forget-password",
  "audios-menshawy",
  "book-videos",
  "book-chapters",
];
const homePage = { path: "/", filePath: "home" };
const courseById = { path: "/courses/:id", filePath: "courses/course" };
const articleById = { path: "/articles/:id", filePath: "news/article" };
const resetPassword = {
  path: "/forgot-password/:token",
  filePath: "reset-password",
};
export const publicRoutes = [
  homePage,
  courseById,
  articleById,
  resetPassword,
  ...publicPages.map((p) => ({ path: p, filePath: p })),
];
