const PageHeader = ({ title }) => {
  return (
    <section>
      <div class="w-100 pt-80 black-layer pb-70 opc65 position-relative">
        <div
          class="fixed-bg"
          style={{ backgroundImage: " url(../images/page-title-bg.jpg)" }}
        ></div>
        <div class="container">
          <div class="page-title-wrap text-center w-100">
            <div class="page-title-inner d-inline-block">
              <h1 class="mb-0">{title} </h1>
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <a href="index.html">الرئيسية</a>
                </li>
                <li class="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PageHeader;
