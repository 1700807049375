import React from "react";

import { Layout, Menu, theme } from "antd";
// import AdminRoutes from "../routes";
import { adminItems } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import "./style.css";

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  };
  return (
    <Layout style={{ minHeight: "100vh", direction: "rtl" }}>
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        className="arabic-text"
      >
        <div className="logo" style={{ height: "50px", margin: "16px" }}>
          <img
            src={"../../../images/header-logo.png"}
            alt="الموجز البسيط"
            height="50"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["articles"]}
          className="arabic-text"
        >
          {adminItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="arabic-text">
        <Header
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="demo-logo" />
          <p
            style={{
              width: "100%",
              textAlign: "left",
              color: "#fff",
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={handleLogout}
          >
            <LogoutOutlined />
          </p>
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            style={{
              padding: 24,
              minHeight: "100%",
              minWidth: "100%",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Mojaz ©{new Date().getFullYear()} Created by LnkSync Team
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
