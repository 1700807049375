import { Pagination } from "antd";
const PaginationComponent = ({ pagination = 1, setPagination, total }) => {
  const handleChange = (page, pageSize) => {
    setPagination(page);
  };
  return (
    <div class="pagination-wrap mt-20 d-flex flex-wrap justify-content-center text-center w-100">
      <ul class="pagination mb-0">
        <Pagination
          pageSize={9}
          defaultCurrent={pagination}
          onChange={handleChange}
          total={total}
        />
      </ul>
    </div>
  );
};
export default PaginationComponent;
