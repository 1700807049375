import { BASE_URL } from "../../services/urls";

const customFetch = async (url, options = {}) => {
  const jwtToken = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };

  let requestBody;

  if (options.body) {
    if (options.body instanceof FormData) {
      requestBody = options.body;
    } else {
      requestBody = JSON.stringify(options.body);
      headers["Content-Type"] = "application/json";
    }
  }

  const response = await fetch(`${BASE_URL}${url}`, {
    method: options.method || "GET",
    headers: headers,
    body: requestBody,
  });

  return response.json();
};

// Example usage

export default customFetch;
