import { Checkbox, Col, Form, Input, Row, message } from "antd";
import {
  SignInNotes,
  SignInPar,
  SignInButton,
  AlreadyHave,
  LoginTerms,
  LoginContainer,
} from "./style";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import customFetch from "../../services/api";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      const response = await customFetch("/admin/login", {
        body: values,
        method: "POST",
      });
      const token = response.data.token;
      const user = response.data.user;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));

      window.location.reload();
      navigate("/admin/articles/list");
    } catch (error) {
      message.error("Password or Email is invalid");
    }
  };
  return (
    <Row style={{ height: "100vh", fontFamily: "Rubic" }}>
      <Col xs={24} sm={24} md={12}>
        <LoginContainer>
          <img
            alt=""
            src="images/logo.png"
            width="166.56px"
            height="69.721px"
          />
          <SignInPar>تسجيل الدخول</SignInPar>
          <SignInNotes>من فضلك ادخل البيانات بدقه عاليه</SignInNotes>
          <Form name="myForm" onFinish={onFinish} form={form}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "من فضلك ادخل الايميل",
                },
                { max: 50, message: "Maximum 50 characters allowed." },
              ]}
              style={{ marginRight: "5px" }}
            >
              <Input placeholder="الايميل *" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "من فضلك ادخل كلمه المرور",
                },
                { max: 50, message: "Maximum 50 characters allowed." },
              ]}
              style={{ marginRight: "5px" }}
            >
              <Input.Password
                placeholder="كلمه المرور"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item>
              <SignInButton>Submit</SignInButton>
            </Form.Item>
          </Form>
        </LoginContainer>
      </Col>
      <Col xs={0} sm={0} md={12}>
        <img alt="" src="images/logo.png" width="100%" height="100%" />
      </Col>
    </Row>
  );
};
export default Login;
