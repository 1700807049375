import HeaderNav from "./header";
import Footer from "./footer";

import "bootstrap/dist/css/bootstrap.rtl.min.css";

import "../assets/css/style.css";
import "../assets/css/rtl.css";

const AppLayout = ({ children }) => {
  return (
    <>
      <HeaderNav />
      {children}
      <Footer />
    </>
  );
};
export default AppLayout;
