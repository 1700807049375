const Footer = () => {
  return (
    <>
      <section>
        <div class="position-relative w-100 pb-235">
          <img
            class="sec-botm-rgt-mckp img-fluid position-absolute"
            src="images/sec-botm-mckp.png"
            alt="Sec Bottom Mockup"
          />
        </div>
      </section>
      <footer>
        <div class="w-100 pt-50 dark-layer position-relative">
          <div
            class="fixed-bg patern-bg back-blend-multiply dark-bg"
            style={{ backgroundImage: "images/pattern-bg.jpg" }}
          ></div>
          <div class="container">
            <div class="footer-data w-100">
              <div class="row">
                <div class="col-md-12 col-12 col-lg-3">
                  <div class="widget w-100 text-center">
                    <div class="logo">
                      <img class="img-fluid" src="images/logo.png" alt="Logo" />
                    </div>
                    <p class="mb-0">الموجز البسيط في علم التجويد</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 col-lg-9">
                  <div class="row">
                    <div class="col-md-4 col-6">
                      <div class="widget w-100">
                        <h4 class="widget-title">روابط هامة</h4>
                        <ul class="mb-0 list-unstyled w-100">
                          <li>
                            <a href="/books" title="">
                              كتاب التجويد
                            </a>
                          </li>
                          <li>
                            <a href="/courses" title="">
                              الدورات
                            </a>
                          </li>
                          <li>
                            <a href="/news" title="">
                              الأخبار
                            </a>
                          </li>
                          <li>
                            <a href="/about" title="">
                              من نحن
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4 col-6">
                      <div class="widget w-100">
                        <h4 class="widget-title">تواصل معانا</h4>
                        <ul class="cont-info-list2 list-unstyled w-100">
                          <li>
                            رقم الهاتف:
                            <br /> <span dir="ltr">0100 225255</span>
                          </li>
                          <li>
                            العنوان:
                            <br /> مصر ، القاهرة{" "}
                          </li>
                        </ul>
                        <div class="social-links d-inline-flex">
                          <a
                            href="https://www.facebook.com/Almojaz.Albaseet"
                            title="Facebook"
                            target="_blank"
                          >
                            <i class="fab fa-facebook-f text-white"></i>
                          </a>
                          <a
                            href="http://bit.ly/Zeinab_Helmy"
                            title="Youtube"
                            target="_blank"
                          >
                            <i class="fab fa-youtube text-white"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-6">
                      <h4 class="widget-title">تحميل التطبيق</h4>
                      <div class="d-block">
                        <a href="#" target="_blank" class="d-block mb-3">
                          <img
                            src="images/download-app/app1.png"
                            alt="Google play"
                          />
                        </a>
                        <a href="#" target="_blank" class="d-block">
                          <img
                            class="mb-3"
                            src="images/download-app/app2.png"
                            alt="App Store"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="bottom-bar dark-bg2 text-center w-100">
        <p class="mb-0">جميع الحقوق محفوظة 2024</p>
      </div>
    </>
  );
};
export default Footer;
