import { useEffect, useMemo, useState } from "react";
import customFetch from "../../services/api";
import Pagination from "../shared/pagination";
import PageHeader from "../shared/page-header";
import { useNavigate } from "react-router-dom";
import { storageBaseUrl } from "../../services/urls";
import { applicant } from "../../services/applicant";
const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(1);

  const navigate = useNavigate();
  const pageValue = useMemo(
    () => courses.slice((pagination - 1) * 10, pagination * 10),
    [pagination, courses]
  );
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await customFetch("/my-courses/" + applicant.id);
        if (response.status === 200) {
          setCourses(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching courses:", error.message);
      }
    };
    fetchCourses();
  }, []);
  const handleViewCourse = (id) => {
    navigate("/courses/" + id);
  };
  return (
    <>
      <PageHeader title="الدورات" />
      <section>
        <div class="w-100 py-5">
          <div class="container">
            <div class="event-wrap w-100">
              {loading ? (
                <h3>جاري عرض الدورات ان وجدت...</h3>
              ) : (
                <div class="row">
                  {(pageValue || []).map(({ id, title, location, image }) => (
                    <div class="col-md-6 col-lg-4">
                      <div class="event-box w-100">
                        <div class="event-img overflow-hidden position-relative w-100">
                          <a
                            onClick={() => {
                              handleViewCourse(id);
                            }}
                            title=""
                          >
                            <img
                              class="img-fluid w-100"
                              src={storageBaseUrl + image}
                              alt="course"
                            />
                          </a>
                        </div>
                        <div class="event-info">
                          <span class="event-loc d-block thm-clr">
                            <i class="fas fa-map-marker-alt"></i> {location}
                          </span>
                          <h3 class="mb-0">
                            <a
                              onClick={() => {
                                handleViewCourse(id);
                              }}
                              title=""
                            >
                              {title}{" "}
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Pagination
        pagination={pagination}
        setPagination={setPagination}
        total={courses.length}
      />
    </>
  );
};
export default Courses;
